<style lang="scss">
    @import "~vuetify/src/styles/styles.sass";

    .footer-button-container {
        text-align: center;
        padding:4px !important;
    }

    .footer-button-container .v-btn__content {
        display:flex;
        flex-direction:column;
    }

    .footer-button-text {
        margin-top: 4px;
        font-size: 12px !important;
    }
    .v-btn--active {
        color: var(--v-primary-base) !important;
    }
</style>

<template>
    <v-footer app tile :height="height" elevation="4" dark>
        <v-row>
            <v-col cols="4" class="footer-button-container">
                <v-btn x-large plain to="/listen">
                    <v-icon>mdi-close</v-icon>
                    <div class="footer-button-text">Button1</div>
                </v-btn>
            </v-col>
            <v-col cols="4" class="footer-button-container">
                <v-btn x-large plain to="/record">
                    <v-icon>mdi-close</v-icon>
                    <div class="footer-button-text">Button2</div>
                </v-btn>
            </v-col>
            <v-col cols="4" class="footer-button-container">
                <v-btn x-large plain to="/vibes">
                    <v-icon>mdi-close</v-icon>
                    <div class="footer-button-text">Button3</div>
                </v-btn>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import config from "../app.config";

export default { 
    data: function () {
        return {
            height: config.footer.height + "px"
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>
